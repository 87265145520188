import { capitalize, startCase } from "lodash-es";
import { nanoid } from "nanoid";

function getContentRule(fieldType) {
  switch (fieldType) {
    case "SHORT_TEXT":
    case "LONG_TEXT":
      return "TEXT";
    case "NUMBER":
      return "DECIMAL";
    default:
      return "";
  }
}

function getCustomDefaultValue(fieldType) {
  switch (fieldType) {
    case "MULTI_SELECT":
    case "MULTIPLE_CHOICE":
    case "FILE_UPLOAD":
    case "IMAGE_UPLOAD":
    case "TABLE":
    case "MATRIX":
      return [];
    case "COUNTER":
    case "RATING":
      return 0;
    case "FILL_IN_THE_BLANKS":
      return {};
    default:
      return "";
  }
}

function getField(fieldType) {
  return {
    id: nanoid(),
    label: capitalize(startCase(fieldType)),
    type: fieldType,
    settings: {
      general: {
        hideLabel: false,
        size: "col-12",
        visibility: "NORMAL",
        placeholder: "",
        tooltip: "",
        dividerType: "SOLID",
        url: "",
      },
      specific: {
        optionsType: "CUSTOM",
        masterTable: "",
        masterTableColumn: "",
        customOptions: "Option 1,Option 2",
        separateOptionsUsing: "COMMA",
        allowToAddNewOptions: false,
        optionsPerLine: 0,
        defaultValue: "CUSTOM",
        autoGenerateValue: {
          prefix: "Form",
          suffix: "DATE_TIME",
        },
        customDefaultValue: getCustomDefaultValue(fieldType),
        showColumnTotal: false,
        allowMultipleFiles: false,
        ratingIcon: "STAR",
        ratingIconCount: 5,
        allowHalfRating: false,
        allowMultipleSignatures: false,
        tableColumns: [],
        tableRowsType: "ON_DEMAND",
        tableFixedRowCount: 0,
        qrValue: false,
        tableFixedRowLabels: [],
        matrixColumns: [
          {
            id: nanoid(),
            label: "column",
          },
          {
            id: nanoid(),
            label: "column",
          },
          {
            id: nanoid(),
            label: "column",
          },
        ],
        matrixRows: [
          {
            id: nanoid(),
            label: "row",
          },
          {
            id: nanoid(),
            label: "row",
          },
          {
            id: nanoid(),
            label: "row",
          },
        ],
        matrixType: "SHORT_TEXT",
        matrixTypeSettings: {},
        textContent: "",
        fibFields: [],
        tabList: [
          {
            id: nanoid(),
            label: "tab 1",
            value: "tab 1",
            panel: 0,
          },
          {
            id: nanoid(),
            label: "tab 2",
            value: "tab 2",
            panel: 0,
          },
        ],
        popupTriggerType: "BUTTON",
        secondaryPanel: "",
        mappedFieldId: "",
        mappedColumnId: "",
        nestedListMaxLevel: 3,
        nestedList: [
          {
            id: nanoid(),
            label: "Item 1",
            level: 1,
            showChildren: true,
            children: [],
          },
          {
            id: nanoid(),
            label: "Item 2",
            level: 1,
            showChildren: true,
            children: [],
          },
        ],
        nestedListItemsPerLine: [],
        nestedListFieldType: "SHORT_TEXT",
        nestedListTypeSettings: {},
        formula: "",
        loginType: "EZOFIS_LOGIN",
        additionalLoginTypes: ["GOOGLE_LOGIN", "MICROSOFT_LOGIN"],
        masterFormTableColumns: [],
      },
      validation: {
        fieldRule: "OPTIONAL",
        contentRule: getContentRule(fieldType),
        minimum: "",
        maximum: "",
        allowedFileTypes: [],
        maxFileSize: 10,
        dateRange: "NONE",
        timeRange: "NONE",
        maxiDays: 0,
        miniDays: 0,
        maxiTime: 0,
        miniTime: 0,
        answerIndicator: "NO",
      },
      aiSettings: {
        formControlValidate: {
          optionsType: "",
          masterFormId: 0,
          masterFormColumn: [],
          formFields: "",
          existingFormFields: [],
          conditionFields: [],
        },
        fileDataValidate: [],
        imageFileValidate: {
          optionsType: "",
          formFields: [],
        },
      },
    },
  };
}

export default function getNewField(fieldType) {
  if (fieldType === "TABLE") {
    const field = getField(fieldType);
    field.settings.specific.tableColumns = [
      getField("SHORT_TEXT"),
      getField("SHORT_TEXT"),
      getField("SHORT_TEXT"),
    ];

    field.settings.specific.tableColumns.forEach((column) => {
      column.label = "Column";
      column.size = "MEDIUM";
    });

    return field;
  }

  if (fieldType === "MATRIX") {
    const field = getField(fieldType);
    field.settings.specific.matrixTypeSettings = getField("SHORT_TEXT");
    return field;
  }

  if (fieldType === "NESTED_LIST") {
    const field = getField(fieldType);
    field.settings.specific.nestedListTypeSettings = getField("SHORT_TEXT");
    return field;
  }

  if (fieldType === "FULL_NAME") {
    let result = [];
    const field = getField("SHORT_TEXT");
    field.label = "First Name";
    field.settings.general.size = "col-6";
    result.push(field);

    const field1 = getField("SHORT_TEXT");
    field1.label = "Last Name";
    field1.settings.general.size = "col-6";

    result.push(field1);

    // console.log(field);
    return result;
  }

  if (fieldType === "ADDRESS") {
    let result = [];
    const field = getField("SHORT_TEXT");
    field.label = "Address Line 1";
    field.settings.general.size = "col-6";

    // result.push(field);

    const field1 = getField("SHORT_TEXT");
    field1.label = "Address Line 2";
    field1.settings.general.size = "col-6";

    const field2 = getField("SINGLE_SELECT");
    field2.label = "City Town";
    field2.settings.general.size = "col-6";
    field2.settings.specific.allowToAddNewOptions = true;
    field2.settings.specific.optionsType = "EXISTING";

    const field3 = getField("SINGLE_SELECT");
    field3.label = "State/Region/Province";
    field3.settings.general.size = "col-6";
    field3.settings.specific.allowToAddNewOptions = true;
    field3.settings.specific.optionsType = "EXISTING";

    const field4 = getField("SHORT_TEXT");
    field4.label = "Zip/Post Code";
    field4.settings.general.size = "col-6";

    const field5 = getField("SINGLE_SELECT");
    field5.label = "Country";
    field5.settings.general.size = "col-6";
    field5.settings.specific.allowToAddNewOptions = true;
    field5.settings.specific.optionsType = "EXISTING";

    result = [...result, field, field1, field2, field3, field4, field5];

    // console.log(field);
    return result;
  }

  if (fieldType === "CONTACT_INFO") {
    let result = [];
    const field = getField("SHORT_TEXT");
    field.label = "First Name";
    field.settings.general.size = "col-6";

    // result.push(field);

    const field1 = getField("SHORT_TEXT");
    field1.label = "Last Name";
    field1.settings.general.size = "col-6";

    const field2 = getField("SINGLE_SELECT");
    field2.label = "Country";
    field2.settings.general.size = "col-6";
    field2.settings.specific.allowToAddNewOptions = true;
    field2.settings.specific.optionsType = "EXISTING";

    const field3 = getField("NUMBER");
    field3.label = "Phone Number";
    field3.settings.general.size = "col-6";

    const field4 = getField("SHORT_TEXT");
    field4.label = "Email";
    field4.settings.general.size = "col-6";
    field4.settings.validation.contentRule = "EMAIL";

    const field5 = getField("SHORT_TEXT");
    field5.label = "Company";
    field5.settings.general.size = "col-6";

    result = [...result, field, field1, field2, field3, field4, field5];

    // console.log(field);
    return result;
  }

  if (fieldType === "PHONE_NUMBER") {
    let result = [];

    const field1 = getField("SINGLE_SELECT");
    field1.label = "Country";
    field1.settings.general.size = "col-6";
    field1.settings.specific.allowToAddNewOptions = true;
    field1.settings.specific.optionsType = "EXISTING";

    const field2 = getField("NUMBER");
    field2.label = "Phone Number";
    field2.settings.general.size = "col-6";

    result = [...result, field1, field2];

    // console.log(field);
    return result;
  }

  if (fieldType === "WEBSITE") {
    const field = getField("SHORT_TEXT");
    field.label = "Website";
    field.settings.validation.contentRule = "WEB";

    return field;
  }

  if (fieldType === "YES_NO") {
    const field = getField("SINGLE_CHOICE");
    field.label = "Question";
    // field1.settings.general.size = "col-6";
    field.settings.specific.customOptions = "Yes,No";

    return field;
  }

  if (fieldType === "LEGAL") {
    const field = getField("MULTIPLE_CHOICE");
    field.label = "Question";
    // field1.settings.general.size = "col-6";
    field.settings.specific.customOptions = "I Accept,I don't Accept";
    field.settings.specific.optionsPerLine = 1;

    return field;
  }

  if (fieldType === "OPINION_SCALE") {
    let result = [];

    const field1 = getField("LABEL");
    field1.label = "Title";

    const field2 = getField("LABEL");
    field2.label = "Description";

    const field3 = getField("RATING");
    field3.label = "Rating";
    // field1.settings.general.size = "col-6";
    field3.settings.specific.ratingIcon = "STAR";
    field3.settings.specific.ratingIconCount = 10;

    result = [...result, field1, field2, field3];

    // console.log(field);
    return result;
  }

  if (fieldType === "REDIRECT_TO_URL") {
    let result = [];

    const field1 = getField("LABEL");
    field1.label = "Title";

    const field2 = getField("LABEL");
    field2.label = "Description";

    const field3 = getField("URL");
    field3.label = "link";

    // const field3 = getField("SHORT_TEXT");
    // field3.label = "URL";
    // field3.settings.validation.contentRule = "WEB";

    result = [...result, field1, field2, field3];

    // console.log(field);
    return result;
  }

  if (fieldType === "CURRENCY") {
    let result = [];

    const field1 = getField("SINGLE_SELECT");
    field1.label = "Currency";
    field1.settings.general.size = "col-4";
    field1.settings.specific.allowToAddNewOptions = false;
    field1.settings.specific.optionsType = "EXISTING";
    field1.settings.general.visibility = "READ_ONLY";

    const field2 = getField("CURRENCY");
    field2.label = "Value";
    field2.settings.general.size = "col-6";

    result = [...result, field1, field2];

    // console.log(field);
    return result;
  }

  return getField(fieldType);
}
