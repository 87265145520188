<template>
  <div id="rules-builder">
    <div v-for="(rule, idx) in rulesClone" :key="rule.id" class="rules-wrapper">
      <!-- header -->

      <PrimaryHeader
        :rule-idx="idx + 1"
        :rule="rule"
        @delete="deleteRule(idx)"
      />

      <!-- ... -->

      <BaseSeparator class="q-my-md" />

      <!-- condition builder -->

      <template v-if="rule.isConditionalRule">
        <ConditionBuilder :conditions.sync="rule.conditions" :fields="fields" />

        <BaseSeparator class="q-my-lg" />
      </template>

      <!-- ... -->

      <!-- calculation builder -->

      <CalculationBuilder
        :is-conditional-rule="rule.isConditionalRule"
        :numeric-fields="numericFields"
        :calculated-fields="calculatedFields"
        :calculations.sync="rule.calculations"
      />

      <!-- ... -->
    </div>

    <!-- add rule buttons -->

    <AddRuleButtons @add-conditonal-rule="addRule(true)" @add-rule="addRule" />

    <!-- ... -->
  </div>
</template>

<script>
import PrimaryHeader from "./components/PrimaryHeader.vue";
import ConditionBuilder from "./components/condition-builder/ConditionBuilder.vue";
import CalculationBuilder from "./components/calculation-builder/CalculationBuilder.vue";
import AddRuleButtons from "./components/AddRuleButtons.vue";
import { cloneDeep, isEmpty } from "lodash-es";

export default {
  name: "RulesSettings",

  components: {
    PrimaryHeader,
    ConditionBuilder,
    CalculationBuilder,
    AddRuleButtons,
  },

  props: {
    mode: {
      type: String,
      default: "NEW",
    },

    form: {
      type: Object,
      required: true,
    },

    rules: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      rulesClone: [],
      fields: [
        {
          id: this.$nano.id(),
          label: "Email",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          label: "Name",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          label: "Phone No",
          type: "SHORT_TEXT",
        },
      ],
      numericFields: [],
      calculatedFields: [],
    };
  },

  watch: {
    form: {
      immediate: true,
      deep: true,
      handler() {
        this.fields = [];
        const panels = [...this.form.panels, ...this.form.secondaryPanels];

        if (!panels.length) {
          return;
        }

        for (const panel of panels) {
          this.fields.push(...panel.fields);
        }

        this.calculatedFields = [];
        this.numericFields = [];

        for (const field of this.fields) {
          if (field.type === "CALCULATED") {
            this.calculatedFields.push(field);
          }

          if (field.type === "TABLE") {
            const hasCalculatedColumns =
              field.settings.specific.tableColumns.some(
                (column) => column.type === "CALCULATED"
              );

            if (hasCalculatedColumns) {
              this.calculatedFields.push(field);
            }

            // const hasNumericColumns = field.settings.specific.tableColumns.some(
            //   (column) =>
            //     column.type === "NUMBER" ||
            //     column.type === "COUNTER" ||
            //     (column.type === "POPUP" &&
            //       field.settings.specific.mappedFieldId)
            // );

            // if (hasCalculatedColumns && hasNumericColumns) {
            //   this.numericFields.push(field);
            // }
            for (const tblField of field.settings.specific.tableColumns) {
              if (tblField.settings.specific.showColumnTotal) {
                this.numericFields.push(tblField);
              }
            }
          }

          if (
            ["NUMBER", "COUNTER", "DATE", "TIME", "DATE_TIME"].includes(
              field.type
            )
          ) {
            this.numericFields.push(field);
          }
        }
      },
    },

    rulesClone: {
      deep: true,
      handler() {
        this.$emit("update:rules", this.rulesClone);
      },
    },
  },

  created() {
    this.rulesClone = cloneDeep(this.rules);

    if (isEmpty(this.rulesClone)) {
      this.addRule(true);
    }
  },

  methods: {
    addRule(isConditionalRule = false) {
      this.rulesClone.push({
        id: this.$nano.id(),
        isConditionalRule,
        name: "",
        conditions: [],
        calculations: [],
      });
    },

    deleteRule(ruleIdx) {
      this.rulesClone.splice(ruleIdx, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
#rules-builder {
  padding: 36px 0px 36px 16px;
  width: 1000px;

  .rules-wrapper {
    border: 1px solid var(--divider-color);
    border-radius: 4px;
    padding: 16px;
    position: relative;
    margin-bottom: 24px;

    .delete-rule {
      position: absolute;
      top: 12px;
      right: 16px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
