<template>
  <div
    id="panel"
    :class="{ 'is-selected': isSelected }"
    @click.stop="selectPanel"
    @dragover.prevent="selectPanel"
    @dragleave="deselectPanel"
    @drop="addField"
  >
    <!-- header-->

    <div class="header">
      <!-- drag icon -->

      <BaseIcon
        v-if="panelType === 'PRIMARY_PANELS'"
        name="drag_indicator"
        inherit-color
        class="drag-icon"
      />

      <!-- ... -->

      <!-- panel index -->

      <div v-if="panelType === 'SECONDARY_PANELS'" class="panel-index">
        {{ panelIndex + 1 }}
      </div>

      <!-- ... -->

      <!-- panel info -->

      <div class="info">
        <div class="title">{{ panelTitle }}</div>
        <div class="description">
          {{ panelDescription }}
        </div>
      </div>

      <!-- ... -->

      <!-- panel actions -->

      <div class="actions">
        <BaseActionButton
          v-for="action in _panelActions"
          :key="action.id"
          v-tooltip.top="action.label"
          is-flat
          no-border
          :icon="action.icon"
          :color="action.value === 'delete' ? 'red' : 'gray'"
          :inherit-color="action.value !== 'delete'"
          :class="{ action: action.value !== 'delete' }"
          @click="$emit(action.value)"
        />
      </div>

      <!-- ... -->
    </div>

    <!-- ... -->

    <BaseSeparator class="q-my-md" />

    <!-- fields -->

    <Draggable
      v-model="panel.fields"
      group="fields"
      inner-class="row q-col-gutter-sm"
      class="panel"
      :class="{ 'no-fields': !hasFields }"
    >
      <div
        v-for="(field, idx) in panel.fields"
        :key="field.id"
        draggable
        :class="field.settings.general.size"
      >
        <Field
          :field="field"
          :is-selected="isFieldSelected(field.id)"
          :secondary-panels="secondaryPanels"
          :form-settings="formSettings"
          :panels="panels"
          @delete="deleteField(idx)"
          @select="selectField(field)"
          @edit="editField(field.type)"
          @settings="showFieldSettings"
          @duplicate="duplicateField(idx)"
          @update="updateField"
        />
      </div>
    </Draggable>

    <!-- ... -->

    <!-- field settings -->

    <FieldSettings
      v-if="isFieldSettingsVisible"
      v-model="isFieldSettingsVisible"
      :field="selectedField"
      :panels="panels"
      :secondary-panels="secondaryPanels"
      @save="updateField"
      @fib-settings="showFibFieldSettings"
      @column-settings="showColumnSettings"
      @matrix-type-settings="showMatrixTypeSettings"
      @nested-list-type-settings="showNestedListTypeSettings"
    />

    <!-- ... -->

    <!-- text editor -->

    <TextEditor
      v-if="isTextEditorVisible"
      v-model="isTextEditorVisible"
      :field="selectedField"
      @save="updateField"
    />

    <!-- ... -->

    <!-- nested list builder -->

    <NestedListBuilder
      v-if="isNestedListBuilderVisible"
      v-model="isNestedListBuilderVisible"
      :field="selectedField"
      @save="updateField"
    />

    <!-- ... -->

    <!-- table builder -->

    <TableBuilder
      v-if="isTableBuilderVisible"
      v-model="isTableBuilderVisible"
      :field="selectedField"
      @save="updateField"
    />

    <!-- ... -->

    <!-- fib field settings -->

    <FieldSettings
      v-if="isFibFieldSettingsVisible"
      v-model="isFibFieldSettingsVisible"
      :field="selectedFibField"
      settings-for="FILL_IN_THE_BLANKS"
      @save="updateFibField"
    />

    <!-- ... -->

    <!-- column settings -->

    <FieldSettings
      v-if="isColumnSettingsVisible"
      v-model="isColumnSettingsVisible"
      :field="selectedColumn"
      settings-for="TABLE"
      :table-field-id="tableFieldId"
      :panels="panels"
      :secondary-panels="secondaryPanels"
      @save="updateColumn"
    />

    <!-- ... -->

    <!-- matrix type settings -->

    <FieldSettings
      v-if="isMatrixTypeSettingsVisible"
      v-model="isMatrixTypeSettingsVisible"
      :field="matrixTypeSettings"
      settings-for="MATRIX"
      :secondary-panels="secondaryPanels"
      @save="updateMatrixTypeSettings"
    />

    <!-- ... -->

    <!-- nested list type settings -->

    <FieldSettings
      v-if="isNestedListTypeSettingsVisible"
      v-model="isNestedListTypeSettingsVisible"
      :field="nestedListTypeSettings"
      settings-for="NESTED_LIST"
      @save="updateNestedListTypeSettings"
    />

    <!-- ... -->
  </div>
</template>

<script>
import Draggable from "@/components/common/Draggable.vue";
import Field from "./components/Field.vue";
import FieldSettings from "./components/field-settings/FieldSettings.vue";
import TextEditor from "@/components/common/text-editor/TextEditor.vue";
import NestedListBuilder from "@/components/common/form/nested-list-field/NestedListBuilder.vue";
import TableBuilder from "@/components/common/form/table-field/TableBuilder.vue";
import { cloneDeep, isEmpty } from "lodash-es";
import getNewField from "@/helpers/new-field.js";

export default {
  name: "Panel",

  components: {
    Draggable,
    Field,
    FieldSettings,
    TextEditor,
    NestedListBuilder,
    TableBuilder,
  },

  props: {
    panel: {
      type: Object,
      required: true,
    },

    isSelected: {
      type: Boolean,
      default: false,
    },

    panelType: {
      type: String,
      required: true,
    },

    panelIndex: {
      type: Number,
      default: 0,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    formSettings: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      panelActions: [
        {
          id: this.$nano.id(),
          icon: "eva-arrow-upward",
          label: "move up",
          value: "move-up",
          for: ["PRIMARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          icon: "eva-arrow-downward",
          label: "move down",
          value: "move-down",
          for: ["PRIMARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          icon: "eva-copy-outline",
          label: "Duplicate",
          value: "duplicate",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          icon: "mdi-tune",
          label: "Settings",
          value: "settings",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
        {
          id: this.$nano.id(),
          icon: "eva-trash-2-outline",
          label: "Delete",
          value: "delete",
          for: ["PRIMARY_PANELS", "SECONDARY_PANELS"],
        },
      ],
      selectedField: {},
      selectedFibField: {},
      selectedColumn: {},
      matrixTypeSettings: {},
      nestedListTypeSettings: {},
      isFieldSettingsVisible: false,
      isTextEditorVisible: false,
      isFibFieldSettingsVisible: false,
      isColumnSettingsVisible: false,
      isMatrixTypeSettingsVisible: false,
      isNestedListTypeSettingsVisible: false,
      isNestedListBuilderVisible: false,
      isTableBuilderVisible: false,
      tableFieldId: "",
    };
  },

  computed: {
    panelTitle() {
      return this.panel.settings.title || "Panel title";
    },

    panelDescription() {
      return this.panel.settings.description || "panel description";
    },

    _panelActions() {
      return this.panelActions.filter((action) =>
        action.for.includes(this.panelType)
      );
    },

    hasFields() {
      return !!this.panel?.fields?.length;
    },

    fieldContent() {
      if (isEmpty(this.selectedField)) {
        return "";
      }

      return this.selectedField.settings.content;
    },
  },

  methods: {
    selectPanel() {
      this.$emit("select");
    },

    deselectPanel() {
      this.$emit("deselect");
    },

    addField(e) {
      const fieldType = e.dataTransfer.getData("fieldType");

      if (!fieldType) {
        return;
      }

      const newField = getNewField(fieldType);
      const panel = cloneDeep(this.panel);

      if (Array.isArray(newField)) {
        newField.forEach((field) => {
          panel.fields.push(field);
        });
      } else {
        panel.fields.push(newField);
      }
      this.$emit("update", panel);
    },

    handleActionClick(action) {
      this.$emit(action);
    },

    selectField(field) {
      this.$emit("deselect");
      this.selectedField = field;
    },

    deSelectField() {
      this.selectedField = {};
    },

    isFieldSelected(fieldId) {
      if (isEmpty(this.selectedField)) {
        return false;
      }

      return this.selectedField.id === fieldId;
    },

    showFieldSettings() {
      this.isFieldSettingsVisible = true;
    },

    editField(fieldType) {
      if (fieldType === "TABLE") {
        this.showTableBuilder();
        return;
      }

      if (fieldType === "NESTED_LIST") {
        this.showNestedListBuilder();
        return;
      }

      this.showTextEditor();
    },

    showTextEditor() {
      this.isTextEditorVisible = true;
    },

    showNestedListBuilder() {
      this.isNestedListBuilderVisible = true;
    },

    showTableBuilder() {
      this.isTableBuilderVisible = true;
    },

    showFibFieldSettings(fibField) {
      this.selectedFibField = fibField;
      this.isFibFieldSettingsVisible = true;
    },

    showColumnSettings(column) {
      this.selectedColumn = column;
      this.tableFieldId = this.selectedField.id;
      this.isColumnSettingsVisible = true;
    },

    showMatrixTypeSettings() {
      this.matrixTypeSettings = cloneDeep(
        this.selectedField.settings.specific.matrixTypeSettings
      );
      this.isMatrixTypeSettingsVisible = true;
    },

    showNestedListTypeSettings() {
      this.nestedListTypeSettings = cloneDeep(
        this.selectedField.settings.specific.nestedListTypeSettings
      );
      this.isNestedListTypeSettingsVisible = true;
    },

    updateField(field) {
      const panel = cloneDeep(this.panel);
      const fieldIdx = panel.fields.findIndex(
        (_field) => _field.id === field.id
      );

      panel.fields[fieldIdx] = field;
      this.$emit("update", panel);
      this.deSelectField();
    },

    duplicateField(fieldIdx) {
      const panel = cloneDeep(this.panel);
      const duplicateField = cloneDeep(panel.fields[fieldIdx]);
      duplicateField.id = this.$nano.id();
      if (duplicateField.type === "TABLE") {
        duplicateField.settings.specific.tableColumns.forEach((column) => {
          column.id = this.$nano.id();
        });
      }
      panel.fields.splice(fieldIdx + 1, 0, duplicateField);
      this.$emit("update", panel);
      this.deSelectField();
    },

    deleteField(fieldIdx) {
      const panel = cloneDeep(this.panel);
      panel.fields.splice(fieldIdx, 1);
      this.$emit("update", panel);
      this.deSelectField();
    },

    updateFibField(fibField) {
      const fibFields = this.selectedField.settings.specific.fibFields;
      const fibFieldIdx = fibFields.findIndex(
        (field) => field.id === fibField.id
      );

      this.$set(
        this.selectedField.settings.specific.fibFields,
        fibFieldIdx,
        fibField
      );
    },

    updateColumn(column) {
      const columns = this.selectedField.settings.specific.tableColumns;
      const columnIdx = columns.findIndex(
        (_column) => _column.id === column.id
      );
      // console.log(columnIdx);

      if (columnIdx > -1) {
        this.$set(
          this.selectedField.settings.specific.tableColumns,
          columnIdx,
          column
        );
      } else {
        this.selectedField.settings.specific.tableColumns.push(column);
      }
    },

    updateMatrixTypeSettings(settings) {
      this.selectedField.settings.specific.matrixTypeSettings = settings;
    },

    updateNestedListTypeSettings(settings) {
      this.selectedField.settings.specific.nestedListTypeSettings = settings;
    },
  },
};
</script>

<style lang="scss" scoped>
#panel {
  background-color: var(--component-bg-color);
  border-radius: 4px;
  border: 1px solid var(--component-bg-color);
  position: relative;
  border: 1px dashed transparent;
  padding: 16px;

  &.is-selected {
    border: 1px dashed var(--primary);

    .header {
      .actions {
        .settings {
          color: var(--primary);
        }
      }
    }
  }

  .header {
    display: flex;
    align-items: center;

    .drag-icon {
      color: var(--hover-bg-color);
    }

    .panel-index {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      border: 1px solid var(--divider-color);
      border-radius: 36px;
    }

    .info {
      margin-left: 16px;
      flex: 1;

      .title {
        @extend .text-md;
        font-weight: 600;
        display: flex;
        align-items: center;
        text-transform: capitalize;
      }

      .description {
        @extend .text-sm;
        color: var(--icon-color-inverted);
      }
    }

    .actions {
      display: flex;
      align-items: center;

      .action {
        color: var(--icon-color-inverted);
      }
    }
  }

  .panel {
    position: relative;
    min-height: 68px;
  }

  .no-fields::before {
    content: "Drag and drop fields from the left to add them here";
    position: absolute;
    width: 100%;
    padding: 24px;
    @extend .text-sm;
    color: var(--icon-color-inverted);
    text-align: center;
    background-color: var(--body-bg-color-light);
    border: 1px dashed var(--body-text-color-inverted);
    border-radius: 4px;
  }
}
</style>
