<template>
  <div id="form-settings">
    <!-- action bar -->

    <ActionBar v-model="tab" :tabs="tabs" @save="saveSettings" />

    <!-- ... -->

    <!-- settings -->

    <BaseScrollbar height="calc(100vh - 125px)">
      <div class="row q-pb-xl">
        <ValidationObserver ref="form">
          <!-- general settings -->

          <GeneralSettings
            v-if="tab === 'GENERAL_SETTINGS'"
            :form="form"
            :mode="mode"
            :name.sync="name"
            :description.sync="description"
            :layout.sync="layout"
            :type.sync="type"
            :qr-fields.sync="qrFields"
            :unique-columns.sync="uniqueColumns"
            :coordinator.sync="coordinator"
            :entryuser.sync="entryuser"
            :user-list="userList"
            :is-active="tab === 'GENERAL_SETTINGS'"
            @click="tab = 'GENERAL_SETTINGS'"
          />

          <!-- ... -->

          <!-- rules settings -->

          <RulesSettings
            v-if="tab === 'RULES_SETTINGS'"
            :form="form"
            :rules.sync="rules"
            @click="tab = 'RULES_SETTINGS'"
          />

          <!-- ... -->

          <WorkInProgress
            v-if="tab === 'NOTIFICATIONS_SETTINGS'"
            feature="Forms Notifications"
          />
          <WorkInProgress
            v-if="tab === 'WELCOME_PAGE_SETTINGS'"
            feature="Forms Welcome Page"
          />
          <WorkInProgress
            v-if="tab === 'THANK_YOU_PAGE_SETTINGS'"
            feature="Thank you Page"
          />
          <WorkInProgress
            v-if="tab === 'THANK_YOU_PAGE_SETTINGS'"
            feature="Thank you Page"
          />
          <WorkInProgress
            v-if="tab === 'HEADER_AND_FOOTER'"
            feature="Header & Footer"
          />
        </ValidationObserver>

        <!-- ... -->
      </div>
    </BaseScrollbar>

    <Confirm
      :value="showAlertModal"
      icon="mdi-alarm"
      icon-color="orange"
      @input="handleNo"
    >
      <template #title>Form type change?</template>

      <template #default>
        <div class="q-py-md">
          <p>
            Now we are going to render the field we made for the
            <b>"{{ type }}"</b>. the field you have already made will be deleted
          </p>

          <p>Are you sure want to continue this?</p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          color="orange"
          @click="handleNo"
        />
        <BaseButton label="yes" color="orange" @click="handleYes" />
      </template>
    </Confirm>

    <!-- ... -->
  </div>
</template>

<script>
import ActionBar from "../components/ActionBar.vue";
import { ValidationObserver } from "vee-validate";
import GeneralSettings from "./components/general-settings/GeneralSettings.vue";
import RulesSettings from "./components/rules-settings/RulesSettings.vue";
import WorkInProgress from "@/components/common/state/WorkInProgress.vue";
import { cloneDeep } from "lodash-es";
import Confirm from "@/components/common/popup/Confirm.vue";
import { user } from "@/api/factory.js";

export default {
  name: "FormSettings",

  components: {
    ActionBar,
    ValidationObserver,
    GeneralSettings,
    RulesSettings,
    WorkInProgress,
    Confirm,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      mode: "NEW",
      tab: "GENERAL_SETTINGS",
      tabs: [
        {
          id: this.$nano.id(),
          label: "general",
          value: "GENERAL_SETTINGS",
        },
        {
          id: this.$nano.id(),
          label: "rules",
          value: "RULES_SETTINGS",
        },
        {
          id: this.$nano.id(),
          label: "notifications",
          value: "NOTIFICATIONS_SETTINGS",
        },
        {
          id: this.$nano.id(),
          label: "welcome page",
          value: "WELCOME_PAGE_SETTINGS",
        },
        {
          id: this.$nano.id(),
          label: "thank you page",
          value: "THANK_YOU_PAGE_SETTINGS",
        },
        {
          id: this.$nano.id(),
          label: "header & footer",
          value: "HEADER_AND_FOOTER",
        },
      ],

      // general settings

      name: "",
      description: "",
      layout: "",
      type: "",
      qrFields: [],
      uniqueColumns: [],

      // specific settings

      rules: [],

      showAlertModal: false,
      userList: [],
      coordinator: [],
      entryuser: [],
    };
  },

  watch: {
    form: {
      immediate: true,
      deep: true,
      handler() {
        const formSettings = cloneDeep(this.form.settings);

        this.name = formSettings.general.name;
        this.description = formSettings.general.description;
        this.layout = formSettings.general.layout;
        this.type = formSettings.general.type;
        this.qrFields = formSettings.general.qrFields;
        this.coordinator = formSettings.general.superUser || [];
        this.entryuser = formSettings.general.entryUser || [];
        this.uniqueColumns = formSettings.general.uniqueColumns;
        this.rules = formSettings.rules;
      },
    },
  },

  created() {
    this.getUsers();
  },

  methods: {
    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      if (payload) {
        payload.map((user) => {
          this.userList.push({
            id: this.$nano.id(),
            label: user.value,
            value: user.id,
          });
        });
      }
    },

    async saveSettings() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      const formSettings = cloneDeep(this.form.settings);

      formSettings.general.name = this.name;
      formSettings.general.description = this.description;
      formSettings.general.layout = this.layout;
      if (
        (this.type === "TASK" ||
          this.type === "SLA" ||
          this.type === "Feedback Form") &&
        this.type !== this.form.settings.general.type
      ) {
        if (this.form.panels[0].fields.length) {
          this.showAlertModal = true;
          return;
        }
      }

      formSettings.general.type = this.type;
      formSettings.general.qrFields = this.qrFields;
      formSettings.general.superUser = this.coordinator;
      formSettings.general.entryUser = this.entryuser;
      formSettings.general.uniqueColumns = this.uniqueColumns;
      formSettings.rules = this.rules;
      this.$emit("update", formSettings);
    },

    handleYes() {
      this.showAlertModal = false;

      const formSettings = cloneDeep(this.form.settings);

      formSettings.general.type = this.type;
      formSettings.general.qrFields = this.qrFields;
      formSettings.general.uniqueColumns = this.uniqueColumns;
      formSettings.rules = this.rules;
      this.$emit("update", formSettings);
    },

    handleNo() {
      this.showAlertModal = false;

      this.type = this.form.settings.general.type;
    },
  },
};
</script>

<style lang="scss" scoped></style>
