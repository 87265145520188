<template>
  <FormSection
    :icon="isActive ? 'eva-options-2' : 'eva-options-2-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>General</template>
    <template #description>
      Name your form and provide detailed description for your reference
    </template>

    <!-- name -->

    <ValidationProvider
      v-slot="{ errors }"
      name="name"
      :rules="{ required: true }"
    >
      <TextField
        :value="name"
        label="name"
        is-mandatory
        :is-readonly="mode === 'EDIT'"
        :error="errors[0]"
        class="q-mb-lg"
        @input="updateName"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- description -->

    <TextAreaField
      :value="description"
      label="description"
      class="q-mb-lg"
      @input="updateDescription"
    />

    <!-- ... -->

    <!-- layout -->

    <FormLayouts :value="layout" class="q-mb-lg" @input="updateLayout" />

    <!-- ... -->

    <!-- types -->

    <FormTypes :value="type" @input="updateType" />

    <!-- ... -->

    <!-- unique columns -->

    <MultiSelectField
      v-if="type === 'MASTER'"
      :value="uniqueColumns"
      label="Unique Fields"
      :options="_fields"
      class="q-mb-lg"
      @input="updateUniqueColumns"
    />

    <!-- ... -->

    <!--  Co-Ordinator -->

    <MultiSelectField
      :value="coordinator"
      label="Co-Ordinator"
      :options="userList"
      class="q-mb-lg"
      @input="updateCoordinator"
    />

    <!-- ... -->

    <!--  Superuser -->

    <MultiSelectField
      v-if="type === 'MASTER'"
      :value="entryuser"
      label="Entry User"
      :options="userList"
      class="q-mb-lg"
      @input="updateEntryuser"
    />

    <!-- ... -->

    <!-- qr fields -->

    <QRGenerate :value="qrFields" :fields="_fields" @input="updateQRFields" />

    <!-- ... -->
  </FormSection>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import FormSection from "@/components/common/item-builder/FormSection.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import FormLayouts from "./components/form-layouts/FormLayouts.vue";
import FormTypes from "./components/FormTypes.vue";
import QRGenerate from "./components/QRGenerate.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";

export default {
  name: "GeneralSettings",

  components: {
    FormSection,
    ValidationProvider,
    TextField,
    TextAreaField,
    FormLayouts,
    FormTypes,
    QRGenerate,
    MultiSelectField,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },

    layout: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },

    qrFields: {
      type: Array,
      default: () => [],
    },

    uniqueColumns: {
      type: Array,
      default: () => [],
    },

    userList: {
      type: Array,
      default: () => [],
    },

    coordinator: {
      type: Array,
      default: () => [],
    },

    entryuser: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [],
    };
  },

  computed: {
    _fields() {
      return this.fields.map((field) => ({
        id: field.id,
        label: field.label || field.type,
        value: field.id,
      }));
    },
  },

  watch: {
    form: {
      immediate: true,
      deep: true,
      handler() {
        this.fields = [];
        const panels = [...this.form.panels, ...this.form.secondaryPanels];

        if (!panels.length) {
          return;
        }
        for (const panel of panels) {
          this.fields.push(...panel.fields);
        }
      },
    },
  },

  methods: {
    updateName(name) {
      this.$emit("update:name", name);
    },

    updateDescription(description) {
      this.$emit("update:description", description);
    },

    updateLayout(layout) {
      this.$emit("update:layout", layout);
    },

    updateType(type) {
      this.$emit("update:type", type);
    },

    updateQRFields(fields) {
      this.$emit("update:qrFields", fields);
    },

    updateUniqueColumns(fields) {
      this.$emit("update:uniqueColumns", fields);
    },

    updateCoordinator(value) {
      this.$emit("update:coordinator", value);
    },

    updateEntryuser(value) {
      this.$emit("update:entryuser", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
