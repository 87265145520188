<template>
  <Modal :value="value" has-footer width="720px" @input="closeModal">
    <!-- title -->

    <template #title>Calculation</template>

    <!-- ... -->

    <template #default>
      <ValidationObserver ref="form">
        <!-- field -->

        <ValidationProvider
          v-slot="{ errors }"
          name="field"
          :rules="{ required: true }"
        >
          <SelectField
            v-model="fieldId"
            is-mandatory
            label="field"
            :options="fields"
            :is-clearable="false"
            :error="errors[0]"
            class="q-mb-lg"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- column -->

        <ValidationProvider
          v-if="isTable"
          v-slot="{ errors }"
          name="column"
          :rules="{ required: true }"
        >
          <SelectField
            v-model="columnId"
            is-mandatory
            label="column"
            :options="columns"
            :is-clearable="false"
            :error="errors[0]"
            class="q-mb-lg"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- formula -->

        <ValidationProvider
          v-slot="{ errors }"
          name="formula"
          :rules="{ required: true, validFormula: true }"
        >
          <FormulaBuilder
            v-model="formula"
            :numeric-fields="_numericFields"
            :error="errors[0]"
          />
        </ValidationProvider>

        <!-- ... -->
      </ValidationObserver>
    </template>

    <!-- footer -->

    <template #footer>
      <BaseButton is-flat label="cancel" class="q-mr-sm" @click="closeModal" />

      <BaseButton label="save" @click="save" />
    </template>

    <!-- ... -->
  </Modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Modal from "@/components/common/popup/Modal.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import FormulaBuilder from "./formula-builder/FormulaBuilder.vue";
import { startCase, lowerCase, isEmpty } from "lodash-es";

export default {
  name: "CalculationPopup",

  components: {
    Modal,
    ValidationObserver,
    ValidationProvider,
    SelectField,
    FormulaBuilder,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    numericFields: {
      type: Array,
      default: () => [],
    },

    calculatedFields: {
      type: Array,
      default: () => [],
    },

    calculation: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      fieldId: "",
      columnId: "",
      formula: [],
    };
  },

  computed: {
    fields() {
      return this.calculatedFields.map((field) => ({
        id: field.id,
        label: field.label || startCase(lowerCase(field.type)),
        value: field.id,
      }));
    },

    isTable() {
      if (!this.fieldId) return false;

      const field = this.calculatedFields.find(
        (field) => field.id === this.fieldId
      );
      return field.type === "TABLE";
    },

    columns() {
      if (!this.isTable) return [];

      const field = this.calculatedFields.find(
        (field) => field.id === this.fieldId
      );
      const columns = field.settings.specific.tableColumns;

      return columns
        .filter((column) => column.type === "CALCULATED")
        .map((field) => ({
          id: field.id,
          label: field.label || startCase(lowerCase(field.type)),
          value: field.id,
        }));
    },

    numericColumns() {
      if (!this.isTable) return [];

      const field = this.calculatedFields.find(
        (field) => field.id === this.fieldId
      );
      const columns = field.settings.specific.tableColumns;

      return columns
        .filter((column) => {
          if (
            ["NUMBER", "COUNTER", "DATE", "TIME", "DATETIME"].includes(
              column.type
            )
          )
            return true;
          if (column.type === "POPUP" && column.settings.specific.mappedFieldId)
            return true;

          return false;
        })
        .map((field) => ({
          id: field.id,
          label: field.label || startCase(lowerCase(field.type)),
          value: field.id,
        }));
    },

    _numericFields() {
      if (this.isTable) return this.numericColumns;

      return this.numericFields.map((field) => ({
        id: field.id,
        label: field.label || startCase(lowerCase(field.type)),
        value: field.id,
      }));
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.fieldId = this.calculation.fieldId || "";
        this.columnId = this.calculation.columnId || "";
        this.formula = this.calculation.formula || [];
      },
    },

    fieldId() {
      if (!this.isTable) {
        this.columnId = "";
      }
    },
  },

  methods: {
    closeModal() {
      this.fieldId = "";
      this.columnId = "";
      this.formula = [];
      this.$emit("input", false);
    },

    async save() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      const calculation = {};

      calculation.id = isEmpty(this.calculation)
        ? this.$nano.id()
        : this.calculation.id;

      calculation.fieldId = this.fieldId;
      calculation.columnId = this.columnId;
      calculation.formula = this.formula;

      this.$emit("save", calculation);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped></style>
