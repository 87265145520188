<template>
  <div id="columns-builder">
    <!-- label -->

    <FormFieldLabel label="columns" is-mandatory />

    <!-- ... -->

    <!-- header -->

    <div class="header row">
      <div class="col row items-center">
        <div class="label">Name</div>
      </div>

      <div class="col row items-center q-mx-sm">
        <div class="label">Data Type</div>
      </div>

      <div class="col row items-center q-mx-sm">
        <div class="label">Size</div>
      </div>

      <div class="row items-center justify-end" style="width: 72px">
        <BaseActionButton
          v-tooltip:secondary.top="'add column'"
          is-flat
          color="secondary"
          icon="eva-plus"
          no-border
          @click="addColumn"
        />
      </div>
    </div>

    <!-- ... -->

    <!-- columns -->

    <Draggable v-model="columns">
      <div
        v-for="(column, idx) in columns"
        :key="column.id"
        class="row q-mb-sm"
      >
        <!-- name -->

        <div class="col row items-center">
          <BaseIcon
            name="drag_indicator"
            size="16px"
            inherit-color
            class="q-mr-sm drag-icon"
          />
          <div class="col">{{ column.label }}</div>
        </div>

        <!-- ... -->

        <!-- type -->

        <div class="col q-mx-sm">
          <SelectField
            :value="column.type"
            :options="columnTypes"
            :is-clearable="false"
            @input="(type) => changeColumnType(idx, type)"
          />
        </div>

        <!-- ... -->

        <!-- size -->

        <div class="col q-mx-sm">
          <SelectField
            v-model="column.size"
            :options="columnSizes"
            :is-clearable="false"
          />
        </div>

        <!-- ... -->

        <!-- settings -->

        <div class="row items-center">
          <BaseActionButton
            v-tooltip.top="'column settings'"
            is-flat
            :is-disabled="!showSettings"
            color="secondary"
            icon="eva-settings-outline"
            no-border
            @click="handleClick(column)"
          />
        </div>

        <!-- ... -->

        <!-- delete -->

        <div class="row items-center">
          <BaseActionButton
            is-flat
            color="red"
            icon="eva-close-outline"
            no-border
            @click="deleteColumn(idx)"
          />
        </div>

        <!-- ... -->
      </div>
    </Draggable>

    <!-- ... -->

    <BaseSeparator v-if="value.length" class="q-mt-md" />

    <!-- error -->

    <FormFieldError v-if="error" :error="error" />

    <!-- ... -->
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import Draggable from "@/components/common/Draggable.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import { cloneDeep, isEmpty, isEqual, merge } from "lodash-es";
import getNewField from "@/helpers/new-field.js";

export default {
  name: "TableColumnsBuilder",

  components: {
    FormFieldLabel,
    FormFieldError,
    Draggable,
    SelectField,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    error: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      columnTypes: [
        {
          id: this.$nano.id(),
          label: "Label",
          value: "LABEL",
        },
        {
          id: this.$nano.id(),
          label: "Serial Number",
          value: "SERIAL_NUMBER",
        },
        {
          id: this.$nano.id(),
          label: "Short Text",
          value: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          label: "Number",
          value: "NUMBER",
        },
        {
          id: this.$nano.id(),
          label: "Counter",
          value: "COUNTER",
        },
        {
          id: this.$nano.id(),
          label: "Date",
          value: "DATE",
        },
        {
          id: this.$nano.id(),
          label: "Time",
          value: "TIME",
        },
        {
          id: this.$nano.id(),
          label: "Chips",
          value: "CHIPS",
        },
        {
          id: this.$nano.id(),
          label: "Single Select",
          value: "SINGLE_SELECT",
        },
        {
          id: this.$nano.id(),
          label: "Multi Select",
          value: "MULTI_SELECT",
        },
        {
          id: this.$nano.id(),
          label: "Single Choice",
          value: "SINGLE_CHOICE",
        },
        {
          id: this.$nano.id(),
          label: "Multiple Choice",
          value: "MULTIPLE_CHOICE",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "File Upload",
        //   value: "FILE_UPLOAD",
        // },
        // {
        //   id: this.$nano.id(),
        //   label: "Popup",
        //   value: "POPUP",
        // },
        {
          id: this.$nano.id(),
          label: "Calculated",
          value: "CALCULATED",
        },
        {
          id: this.$nano.id(),
          label: "Image",
          value: "IMAGE",
        },
      ],
      columnSizes: [
        {
          id: this.$nano.id(),
          label: "Small",
          value: "SMALL",
        },
        {
          id: this.$nano.id(),
          label: "Medium",
          value: "MEDIUM",
        },
        {
          id: this.$nano.id(),
          label: "Large",
          value: "LARGE",
        },
      ],
      columns: [],
      showSettings: true,
    };
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        if (isEmpty(this.value) && isEmpty(this.columns)) {
          return;
        }

        if (!isEmpty(this.value) && isEmpty(this.columns)) {
          this.columns = cloneDeep(this.value);
          return;
        }

        if (!isEqual(this.value, this.columns.length)) {
          merge(this.columns, cloneDeep(this.value));
        }
      },
    },

    columns: {
      deep: true,
      handler() {
        this.$emit("input", this.columns);
      },
    },
  },

  created() {
    this.columns = cloneDeep(this.value);
  },

  methods: {
    changeColumnType(columnIdx, type) {
      //this.showSettings = false;

      const newColumn = getNewField(type);
      newColumn.label = this.columns[columnIdx].label;
      newColumn.size = this.columns[columnIdx].size;
      newColumn.id = this.columns[columnIdx].id;

      this.$set(this.columns, columnIdx, newColumn);
    },

    addColumn() {
      const newColumn = getNewField("SHORT_TEXT");
      newColumn.label = "Column";
      newColumn.size = "MEDIUM";
      this.columns.push(newColumn);
    },

    deleteColumn(columnIdx) {
      this.columns.splice(columnIdx, 1);
    },

    handleClick(column) {
      this.$emit("column-settings", column);
    },
  },
};
</script>

<style lang="scss" scoped>
#columns-builder {
  .header {
    border-bottom: 1px solid var(--divider-color);
    margin-bottom: 16px;
    padding-bottom: 4px;

    .label {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
  }

  .drag-icon {
    color: var(--icon-color-inverted);
    cursor: move;
  }
}
</style>
